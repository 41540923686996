<template>
  <div class="mobile-main" :class="`orientation-${orientation}`">
    <RtbControlButton
      class="mobile-btn mobile-btn--top-right"
      @click="menuActive = !menuActive"
    >
      <v-icon dark>menu</v-icon>
    </RtbControlButton>

    <div class="mobile-layout">
      <div
        class="mobile-layout__first"
        :class="{
          'mobile-layout__first--grow':
            !isIRLSession && (isBuzzIn || isResultsMode)
        }"
        v-if="!isUserHidden"
      >
        <template v-if="user.teamID">
          <JeopardAiTeamTag
            :team="viewerTeam"
            v-if="mission.behavior === MissionType.JeopardAI"
            class="mobile-team-tag"
          />

          <TeamTag
            v-else
            class="mobile-team-tag"
            :mode="mode || ''"
            :team="viewerTeam"
          />
        </template>

        <div
          v-if="user.id && !isIRLSession"
          class="mobile-user-card"
          :class="{
            'mobile-user-card--focused': isPinnedTeamUser,
            'mobile-user-card--scaled': isScaled
          }"
        >
          <GamePlayerCard :user="player" />
        </div>

        <MobileMedia v-if="isIRLSession" />
      </div>

      <div v-if="!isPinnedTeamUser" class="mobile-layout__second">
        <v-flex
          class="play-mode"
          :style="
            mission.behavior === MissionType.JeopardAI && mode === Mode.Play
              ? { position: 'static' }
              : {}
          "
        >
          <div class="play-mode__countdown" v-if="isTimeAvailable">
            <PillCountdown :endTime="endTime" />
          </div>

          <JeopardAiResultsMobile
            v-if="
              mission.behavior === MissionType.JeopardAI &&
              getCurrentMode === Mode.Over
            "
          />

          <Mission
            v-else-if="isWelcomeVisible"
            :key="mission.id"
            :mode="mode"
            :mission="mission"
          >
            <MobileWelcome
              v-if="missionType === MissionType.QRCode"
              text="Hang tight while everyone signs in."
            />
            <MobileWelcome
              v-else
              :text="isTurn ? `It's your turn` : undefined"
            />
          </Mission>
          <MobileResult v-else-if="isResultsMode" />

          <MusicalKeysMobile
            v-else-if="missionType === MissionType.MusicalKeys"
          />
          <JeopardAIMobile
            v-else-if="
              mission.behavior === MissionType.JeopardAI && mode === Mode.Play
            "
          />

          <MobilePriceIsRight
            :mission="mission"
            :key="mission.id"
            :mode="mode"
            v-else-if="mission.behavior === MissionType.PriceIsRight"
          />
          <MobileVoting :key="mission.id" v-else-if="isVoting" />

          <template
            v-else-if="
              missionType === MissionType.MatchGame &&
              [Mode.Social, Mode.Play].includes(mode)
            "
          >
            <ImageMatch v-if="isLandScape" :key="mission.id" />
            <Mission v-else>
              <h1>{{ forceLandscapeText }}</h1>
            </Mission>
          </template>

          <template
            v-else-if="
              missionType === MissionType.FactMatch &&
              getCurrentMode === Mode.Huddle
            "
          >
            <FactMatch v-if="isLandScape" :key="mission.id" />
            <Mission v-else>
              <h1>{{ forceLandscapeText }}</h1>
            </Mission>
          </template>

          <QuestionCards mobile v-else-if="isQuestionCards" :key="mission.id" />

          <AwardMobile v-else-if="isAward" :key="mission.id" />

          <Mission :mode="mode" :mission="mission" v-else>
            <MultipleChoice
              v-if="missionType === MissionType.MultipleChoice"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
              mobile
            />

            <MobileDrawing
              v-else-if="isDrawingMission"
              :isLandScape="isLandScape"
              :mission="mission"
              :mode="mode"
            />

            <SpiralDrawingMobile
              v-else-if="missionType === MissionType.SpiralDrawing"
              :isLandScape="isLandScape"
            />

            <Photo
              v-else-if="missionType === MissionType.Photo"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
            />
            <PhotoBooth
              v-else-if="missionType === MissionType.PhotoBooth"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
            />
            <TwoTruths
              v-else-if="missionType === MissionType.TwoTruths"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
              mobile
            />
            <AwardVote
              v-else-if="isAwardVote"
              :mode="mode"
              :mission="mission"
            />
            <TwoTruthsAsset
              v-else-if="missionType === MissionType.TwoTruthsReveal"
              :key="`${mission.id}-${gameStatus.twoTruthsID}`"
              :mode="mode"
            />
            <Poll
              v-else-if="missionType === MissionType.Poll"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
              mobile
            />
            <Giphy
              v-else-if="missionType === MissionType.Giphy"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
              mobile
            />
            <TextInput
              v-else-if="
                missionType === MissionType.Text ||
                missionType === MissionType.FreeForm ||
                missionType === MissionType.TeamName
              "
              :key="mission.id"
              :mode="mode"
              :mission="mission"
              :maxTextLength="missionType === MissionType.TeamName ? 30 : null"
              mobile
            />
            <TakeVideo
              v-else-if="videoMission"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
            />
            <Lipdub
              v-else-if="missionType === MissionType.Lipdub"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
              mobile
            />
            <TakePhoto
              v-else-if="missionType === MissionType.TakePhoto"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
            />
            <Categories
              v-else-if="missionType === MissionType.Categories"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
              class="mobile"
            />
            <UnconditionalPositiveRegardMobile
              v-else-if="
                missionType === MissionType.UnconditionalPositiveRegard
              "
              :key="mission.id"
            />
            <WordCloudMobile v-else-if="isWordCloud" />

            <MobileBuzzIn
              v-else-if="isBuzzable"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
              :isLandScape="isLandScape"
            />
            <DiscussionGroupMobile
              v-else-if="missionType === MissionType.DiscussionGroup"
              :key="mission.id"
              :isLandScape="isLandScape"
            />
            <RatingPoll
              v-else-if="missionType === MissionType.RatingPoll"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
              :isLandScape="isLandScape"
            />
            <TextOnly
              v-else-if="isTextOnly"
              :key="mission.id"
              :mode="mode"
              :mission="mission"
            />
          </Mission>
          <div
            class="play-mode__wrap"
            v-if="
              isMissionMeta &&
              mission.points > 0 &&
              mission.behavior !== MissionType.JeopardAI
            "
          >
            <RtbPill> {{ mission.points }} PTS </RtbPill>
          </div>
          <div
            v-if="skipButton"
            class="play-mode__wrap skip-video"
            @click="handleSkipVideo()"
            style="cursor: pointer"
          >
            <RtbPillPurple> SKIP VIDEO </RtbPillPurple>
          </div>
        </v-flex>
      </div>
    </div>
    <DraggableBtn v-model="speaking" v-if="hasPushToTalkBtn" />
    <MobileSideBar
      v-model="menuActive"
      @openChat="openChat"
      @onSwapPhone="swapPhone = true"
      @onChangeAV="aVSettings = true"
    />
    <SwapPhone v-model="swapPhone" />
    <MobileAVSettings v-model="aVSettings" />
    <MobileChat v-if="chatVisible" @closeChat="chatActive = false" />
    <Popup
      :isOpen="zoomMissionAssetPhoto.visible"
      @onClose="closePopup()"
      @onClickOutside="closePopup()"
      :size="isViewerPresenter ? 'xxl' : ''"
      :style="assetPopupStyle"
    >
      <v-layout column justify-center>
        <div
          style="margin-top: -16px"
          class="text-center mb-2"
          v-html="popupTitle"
        />
        <img
          :src="zoomMissionAssetPhoto.photo"
          alt="missions photo"
          style="
            max-width: auto;
            max-height: 80vh;
            transform: translateZ(0);
            object-fit: contain;
          "
        />
      </v-layout>
    </Popup>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { debounce } from "lodash"

import MissionType from "@shared/enums/Mission"
import Mission from "@shared/Mission"
import ModeMapper from "@/components/GroupTeams/Common/ModeMapper"
import PlayMode from "@/components/GroupTeams/Common/GameModes/PlayMode"
import GamePlayerCard from "@/components/GroupTeams/Common/Player/GamePlayerCard/GamePlayerCard.vue"

import { RtbPill, RtbControlButton, RtbPillPurple } from "@/components/ui"
import CustomDrawer from "@/components/CustomDrawer"
import PillCountdown from "@/components/GroupTeams/Common/PillCountdown"

import TeamTag from "@/components/GroupTeams/Common/TeamTag.vue"
import DraggableBtn from "./DraggableBtn"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import Mode from "@shared/enums/Mode"
import ORIENTATIONS from "./orientations"

import UserSoundService from "@/services/user-sound.service"

const COUNTDOWN_START_MODES = [Mode.Play, Mode.Huddle, Mode.Social]

const appHeight = () => {
  document.documentElement.style.setProperty(
    "--app-height",
    `${window.innerHeight}px`
  )
}

import { onWindowResize } from "@/plugins/window"

const debouncedHeight = debounce(() => appHeight(), 300)

export default {
  name: "MobileMain",
  components: {
    MissionCard,
    GamePlayerCard,
    DraggableBtn,
    CustomDrawer,
    RtbControlButton,
    ModeMapper,
    PlayMode,
    RtbPillPurple,
    PillCountdown,
    RtbPill,
    TeamTag,

    Popup: () => import("@/components/Popups/Popup"),
    MobileMedia: () => import("./MobileMedia.vue"),
    AwardVote: () =>
      import("@/components/GroupTeams/Common/Games/AwardVote.vue"),
    AwardMobile: () =>
      import("@/components/GroupTeams/Common/GameModes/Social/AwardMobile.vue"),
    JeopardAiResultsMobile: () =>
      import("@/components/Main/JeopardAiResultsMobile.vue"),
    JeopardAiTeamTag: () =>
      import("@/components/GroupTeams/Common/JeopardAiTeamTag.vue"),
    JeopardAIMobile: () =>
      import(
        "@/components/GroupTeams/Common/Games/JeopardAI/JeopardAIMobile.vue"
      ),
    UnconditionalPositiveRegardMobile: () =>
      import(
        "@/components/GroupTeams/Common/Games/UnconditionalPositiveRegard/UnconditionalPositiveRegardMobile.vue"
      ),
    WordCloudMobile: () =>
      import(
        "@/components/GroupTeams/Common/Games/WordCloud/WordCloudMobile.vue"
      ),
    MusicalKeysMobile: () =>
      import(
        "@/components/GroupTeams/Common/Games/MusicalKeys/MusicalKeysMobile.vue"
      ),
    QuestionCards: () =>
      import(
        "@/components/GroupTeams/Common/Games/QuestionCards/QuestionCards.vue"
      ),
    MobilePriceIsRight: () =>
      import("@/components/GroupTeams/Mobile/MobilePriceIsRight.vue"),
    MobileSideBar: () => import("./MobileSideBar.vue"),
    SwapPhone: () => import("./SwapPhone.vue"),
    MobileAVSettings: () => import("./MobileAVSettings.vue"),
    MobileChat: () => import("./MobileChat.vue"),
    MobileWelcome: () => import("./MobileWelcome.vue"),
    MobileBuzzIn: () => import("./MobileBuzzIn.vue"),
    MobileDrawing: () => import("./MobileDrawing.vue"),
    SpiralDrawingMobile: () =>
      import(
        "@/components/GroupTeams/Common/Games/SpiralDrawing/SpiralDrawingMobile.vue"
      ),
    MobileResult: () => import("./MobileResult.vue"),
    MobileVoting: () => import("./Voting/MobileVoting.vue"),
    Poll: () => import("@/components/GroupTeams/Common/Games/Poll.vue"),
    Giphy: () => import("@/components/GroupTeams/Common/Games/Giphy.vue"),
    Lipdub: () => import("@/components/GroupTeams/Common/Games/Lipdub.vue"),
    TextOnly: () => import("@/components/GroupTeams/Common/Games/TextOnly.vue"),
    TakeVideo: () =>
      import("@/components/GroupTeams/Common/Games/TakeVideo.vue"),
    TakePhoto: () =>
      import("@/components/GroupTeams/Common/Games/TakePhoto.vue"),
    Photo: () => import("@/components/GroupTeams/Common/Games/Photo.vue"),
    TextInput: () =>
      import("@/components/GroupTeams/Common/Games/TextInput.vue"),
    Categories: () =>
      import("@/components/GroupTeams/Common/Games/Categories/Categories.vue"),
    PhotoBooth: () =>
      import("@/components/GroupTeams/Common/Games/PhotoBooth.vue"),
    TwoTruths: () =>
      import("@/components/GroupTeams/Common/Games/TwoTruths.vue"),
    TwoTruthsAsset: () =>
      import("@/components/GroupTeams/Common/Games/TwoTruthsAsset"),
    MultipleChoice: () =>
      import("@/components/GroupTeams/Common/Games/MultipleChoice.vue"),
    ImageMatch: () =>
      import("@/components/GroupTeams/Common/GameModes/Social/ImageMatch.vue"),
    FactMatch: () =>
      import("@/components/GroupTeams/Common/GameModes/Social/FactMatch.vue"),
    Drawing: () => import("@/components/GroupTeams/Common/Games/Drawing.vue"),
    RatingPoll: () =>
      import("@/components/GroupTeams/Common/Games/RatingPoll/RatingPoll.vue"),
    Mission: () => import("@/components/GroupTeams/Common/Games/Mission.vue"),
    MissionContainer: () =>
      import("@/components/GroupTeams/Common/Games/MissionContainer.vue"),
    DiscussionGroupMobile: () =>
      import(
        "@/components/GroupTeams/Common/Games/DiscussionGroup/DiscussionGroupMobile.vue"
      )
  },
  data() {
    return {
      speaking: false,
      menuActive: false,
      chatActive: false,
      swapPhone: false,
      aVSettings: false,
      MissionType,
      Mode,
      zoomMissionAssetPhoto: {
        visible: false,
        title: null,
        photo: null
      }
    }
  },
  props: {
    orientation: {
      required: false,
      default: ORIENTATIONS.PORTAIT,
      type: String
    }
  },
  mounted() {
    this.$bus.$on("zoomMissionAssetPhoto", this.onZoomMissionAssetPhoto)
    onWindowResize()
    window.addEventListener("orientationchange", debouncedHeight)
    appHeight()
  },
  beforeDestroy() {
    window.removeEventListener("orientationchange", debouncedHeight)
  },
  watch: {
    speaking(value) {
      if (value) return UserSoundService.makeSpeaker(this.user)

      UserSoundService.revokeSpeaker(this.user)
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost", "client"]),
    ...mapGetters([
      "game",
      "orgID",
      "gameID",
      "gameHost",
      "isTextChatOn",
      "gameStatus",
      "getCurrentMode",
      "onlineUsersGroupedByTeam"
    ]),
    viewerTeam() {
      return this.$store.getters.chats[this.user?.teamID]
    },
    chatVisible() {
      return this.isTextChatOn && this.chatActive
    },
    isPinnedTeamUser() {
      return (
        this.user?.teamID === this.game?.pinnedTeamID &&
        this.missionType !== MissionType.QuestionCards
      )
    },
    isLandScape() {
      return this.orientation === ORIENTATIONS.LANDSCAPE
    },
    skipButton() {
      return (
        this.game.diy &&
        this.mode !== Mode.Results &&
        ((this.missionType === MissionType.Info && this.mission.youtube) ||
          this.missionType === MissionType.YouTube)
      )
    },
    isResultsMode() {
      return (
        this.mode === Mode.Results &&
        !this.isWordCloud &&
        ![MissionType.RatingPoll].includes(this.missionType)
      )
    },
    isUserHidden() {
      return (
        this.isVoting ||
        this.videoMission ||
        this.isDrawingMission ||
        this.isAward ||
        [
          MissionType.FactMatch,
          MissionType.MatchGame,
          MissionType.TakePhoto,
          MissionType.SpiralDrawing,
          MissionType.PhotoBooth,
          MissionType.DiscussionGroup
        ].includes(this.missionType)
      )
    },
    isAwardVote() {
      return MissionType.Award === this.missionType && this.mode === Mode.Play
    },
    isAward() {
      return MissionType.Award === this.missionType && this.mode === Mode.Social
    },
    isPhotoBooth() {
      return MissionType.PhotoBooth === this.missionType
    },
    scribeLabel() {
      return this.game?.scribeLabel || this.client.scribeLabel || "Scribe"
    },
    isIndividualMission() {
      return String(this.playType).toLowerCase().indexOf("individual") > -1
    },
    playType() {
      return this.mission ? this.mission.playType : null
    },
    onlineUsersGroupedByThisTeam() {
      return this.onlineUsersGroupedByTeam?.[this.user?.teamID] ?? []
    },
    isIRLSession() {
      return this.$store.getters["auth/isIRLSession"]
    },
    hasPushToTalkBtn() {
      return (
        !this.isIRLSession &&
        !this.$store.getters.everyoneCanHearOnlyHost &&
        !this.game?.pushToTalkHidden
      )
    },
    mission() {
      return this.$store.getters.getCurrentMission
    },
    isWelcomeVisible() {
      const { isScribe } = this.$store.getters
      return (
        (this.mission && this.mode === null) ||
        (this.isQuestionCards && !isScribe) ||
        this.missionType === MissionType.QRCode
      )
    },
    mode() {
      switch (this.getCurrentMode) {
        case Mode.Huddle:
          return Mode.Play
        case Mode.Play:
        case Mode.Social:
        case Mode.Voting:
        case Mode.Results:
          return this.getCurrentMode
        default:
          return null
      }
    },
    isScaled() {
      if (
        this.isBuzzIn &&
        this.user.selected &&
        !this.$store.getters.isUserMuted(this.user)
      )
        return true

      return (
        this.getCurrentMode === Mode.Social &&
        this.user.selected &&
        this.missionType !== MissionType.TwoTruthsReveal
      )
    },
    isBuzzIn() {
      return this.missionType === MissionType.BuzzIn
    },
    isQuestionCards() {
      return this.missionType === MissionType.QuestionCards
    },
    missionType() {
      return this.mission?.behavior
    },
    isMissionMeta() {
      return (
        !!this.mode &&
        !!this.mission &&
        this.getCurrentMode !== Mode.Results &&
        this.missionType !== MissionType.YouTube &&
        this.getCurrentMode !== Mode.Voting
      )
    },
    endTime() {
      const int = parseInt(this.gameStatus.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    },
    isTextOnly() {
      return [
        MissionType.BuzzIn,
        MissionType.Info,
        MissionType.Link,
        MissionType.OrderTheCards,
        MissionType.TwoTruthsReveal,
        MissionType.Slides,
        MissionType.HandMovement
      ].includes(this.missionType)
    },
    isDrawingMission() {
      return Mission.isDrawing(this.mission)
    },
    isTimeAvailable() {
      if (!this.isMissionMeta) return false

      if (this.game?.autopilot) {
        return (
          this.isHost ||
          (COUNTDOWN_START_MODES.includes(this.mode) && this.endTime > 0)
        )
      }

      return COUNTDOWN_START_MODES.includes(this.mode) && this.mission.time > 0
    },
    videoMission() {
      return [
        MissionType.Video,
        MissionType.VideoTeam,
        MissionType.VideoIndividual
      ].includes(this.missionType)
    },
    isVoting() {
      return this.mode === Mode.Voting
    },
    isBuzzable() {
      return Mission.isBuzzable(this.mission)
    },
    isWordCloud() {
      return this.missionType === MissionType.WordCloud
    },
    forceLandscapeText() {
      return "Please turn phone to landscape mode"
    },
    isTurn() {
      return (
        this.missionType === MissionType.Slides &&
        this.$store.getters.getSelectedMeetingUsers?.[this.user.id]
      )
    },
    player() {
      const plays = this.$store.getters.missionPlaysArray
      return {
        ...this.user,
        playStatus: {
          submitted: plays.some(play => play.userID === this.user.id)
        }
      }
    }
  },
  methods: {
    openChat() {
      this.chatActive = true
      this.menuActive = false
    },
    onZoomMissionAssetPhoto({ visible = null, photo = null, title = null }) {
      this.zoomMissionAssetPhoto = { visible, photo, title }
    },
    closePopup() {
      this.zoomMissionAssetPhoto = { visible: false, title: false }
    }
  }
}
</script>

<style lang="scss">
:root {
  --app-height: 100%;
}

html {
  overflow-y: auto !important;
}

.application--wrap {
  min-height: auto;
}

.mobile-main {
  height: var(--app-height);
  position: relative;
  color: #fff;

  .VueCarousel .carousel {
    max-width: 100% !important;
  }

  .VueCarousel-inner {
    width: 100%;
  }

  .voting-container .voting-element,
  .voting-container .voting-element-star-info {
    max-width: 96% !important;
  }

  .voting-container .voting-element {
    width: 330px;
    overflow: hidden;
    margin: 4px auto;
    width: calc(80vh);
  }

  .drawing-picker {
    top: 50%;
    transform: translateY(-50%);
  }
}

.mobile-menu-btn {
  position: absolute;
  z-index: 4;
  top: 16px;
  right: 16px;

  height: 48px;
  width: 48px;

  background: #393b42;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #586475;
  }
}

.mobile-btn {
  height: 48px;
  width: 48px;

  background: #393b42;
  border-radius: 4px;

  position: fixed;
  z-index: 20;

  &--top-right {
    top: 16px;
    right: 16px;
  }

  &--bottom-right {
    bottom: 16px;
    right: 16px;
  }
}

.mobile-team-tag {
  font-size: 12px;
  min-width: 160px;
  justify-content: center;
  margin-top: -8px;

  .orientation-portrait & {
    min-width: 43vw;
    margin-left: auto;
    margin-right: auto;
    padding: 4px 18px;
    font-size: 12px;
  }
}

.mobile-user-card {
  margin-top: 16px;

  .orientation-landscape & {
    width: 24vw;
  }

  .orientation-portrait & {
    width: 43vw;
    margin-left: auto;
    margin-right: auto;
  }

  &--scaled {
    transition: width 0.3s;
    z-index: 1;

    .orientation-landscape & {
      width: 60vh;
    }

    .orientation-portrait & {
      width: max(30vh, 50vw);
    }
  }

  &--focused {
    transition: width 0.3s;

    .orientation-landscape & {
      width: 60vw;
    }

    .orientation-portrait & {
      width: 100%;
    }
  }
}

.mobile-layout {
  text-align: center;
  display: flex;
  height: 100%;

  .orientation-portrait & {
    flex-direction: column;
  }

  &__first {
    padding-top: 24px;
    padding-left: 16px;
    position: relative;
    display: flex;
    flex-direction: column;

    .orientation-landscape & {
      padding-bottom: 24px;
    }

    .orientation-portrait & {
      align-items: center;
      padding-right: 16px;
    }

    &--grow {
      .orientation-landscape & {
        flex: 1;
      }

      .orientation-portrait & {
        flex: 0.4;
      }
    }
  }

  &__second {
    flex: 1;
    overflow: auto;
  }
}

.mobile-layout {
  .team-tag__title {
    flex: 1;
  }

  .play-mode__countdown {
    margin-left: auto;
    margin-right: auto;
    height: 0;
    position: relative;
    z-index: 10;
    top: -16px;
  }

  .play-mode__wrap {
    top: -16px;
    margin-left: auto;
    margin-right: auto;
    height: 0;
    position: relative;
    z-index: 10;
  }

  .mission {
    z-index: 2;
    background-image: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    ) !important;
    min-height: 100% !important;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
    .disabled {
      pointer-events: none;
    }
  }

  .asset-column {
    position: relative;
  }

  .play-asset {
    text-align: right;
    background: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    );
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(248, 249, 251, 0.33);

    .asset {
      @extend .rtb-border, .rtb-border-radius;
    }
  }

  .play-mode {
    padding: 24px 16px;
    height: 100%;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .orientation-landscape & {
      .voting-container .voting-element,
      .voting-container .voting-element-star-info {
        max-width: 66% !important;
      }
    }
  }

  .current-mode-debug {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: left;
    color: $color-red2;
    z-index: 99;
    font-size: 8px;
    padding: 10px;
  }

  .player-icon__meta-tag {
    z-index: 100;
  }
}

.mobile-mission-transition {
  &__transition-enter-active,
  &__transition-leave-active {
    transition: opacity ease 0.5s;
    opacity: 1;
  }
  &__transition-enter {
    opacity: 0;
  }
  &__transition-leave-to {
    opacity: 0;
  }
}
</style>
