var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "draggable" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "draggable",
            rawName: "v-draggable",
            value: _vm.draggableValue,
            expression: "draggableValue",
          },
        ],
        staticClass: "draggable-btn",
      },
      [
        _c(
          "div",
          { staticClass: "draggable-btn__interaction-area" },
          [
            _vm._m(0),
            _c("div", {
              ref: _vm.handleId,
              staticClass: "draggable-btn__handle-area",
              on: { touchstart: _vm.onDragStart, touchend: _vm.onDragEnd },
            }),
            _c("div", {
              staticClass: "draggable-btn__action-area",
              on: { touchstart: _vm.onTouchStart, touchend: _vm.onTouchEnd },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("DraggableBtnContent", { attrs: { observable: _vm.observable } })
  },
]
render._withStripped = true

export { render, staticRenderFns }