var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-main", class: "orientation-" + _vm.orientation },
    [
      _c(
        "RtbControlButton",
        {
          staticClass: "mobile-btn mobile-btn--top-right",
          on: {
            click: function ($event) {
              _vm.menuActive = !_vm.menuActive
            },
          },
        },
        [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("menu")])],
        1
      ),
      _c("div", { staticClass: "mobile-layout" }, [
        !_vm.isUserHidden
          ? _c(
              "div",
              {
                staticClass: "mobile-layout__first",
                class: {
                  "mobile-layout__first--grow":
                    !_vm.isIRLSession && (_vm.isBuzzIn || _vm.isResultsMode),
                },
              },
              [
                _vm.user.teamID
                  ? [
                      _vm.mission.behavior === _vm.MissionType.JeopardAI
                        ? _c("JeopardAiTeamTag", {
                            staticClass: "mobile-team-tag",
                            attrs: { team: _vm.viewerTeam },
                          })
                        : _c("TeamTag", {
                            staticClass: "mobile-team-tag",
                            attrs: {
                              mode: _vm.mode || "",
                              team: _vm.viewerTeam,
                            },
                          }),
                    ]
                  : _vm._e(),
                _vm.user.id && !_vm.isIRLSession
                  ? _c(
                      "div",
                      {
                        staticClass: "mobile-user-card",
                        class: {
                          "mobile-user-card--focused": _vm.isPinnedTeamUser,
                          "mobile-user-card--scaled": _vm.isScaled,
                        },
                      },
                      [_c("GamePlayerCard", { attrs: { user: _vm.player } })],
                      1
                    )
                  : _vm._e(),
                _vm.isIRLSession ? _c("MobileMedia") : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        !_vm.isPinnedTeamUser
          ? _c(
              "div",
              { staticClass: "mobile-layout__second" },
              [
                _c(
                  "v-flex",
                  {
                    staticClass: "play-mode",
                    style:
                      _vm.mission.behavior === _vm.MissionType.JeopardAI &&
                      _vm.mode === _vm.Mode.Play
                        ? { position: "static" }
                        : {},
                  },
                  [
                    _vm.isTimeAvailable
                      ? _c(
                          "div",
                          { staticClass: "play-mode__countdown" },
                          [
                            _c("PillCountdown", {
                              attrs: { endTime: _vm.endTime },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.mission.behavior === _vm.MissionType.JeopardAI &&
                    _vm.getCurrentMode === _vm.Mode.Over
                      ? _c("JeopardAiResultsMobile")
                      : _vm.isWelcomeVisible
                      ? _c(
                          "Mission",
                          {
                            key: _vm.mission.id,
                            attrs: { mode: _vm.mode, mission: _vm.mission },
                          },
                          [
                            _vm.missionType === _vm.MissionType.QRCode
                              ? _c("MobileWelcome", {
                                  attrs: {
                                    text: "Hang tight while everyone signs in.",
                                  },
                                })
                              : _c("MobileWelcome", {
                                  attrs: {
                                    text: _vm.isTurn
                                      ? "It's your turn"
                                      : undefined,
                                  },
                                }),
                          ],
                          1
                        )
                      : _vm.isResultsMode
                      ? _c("MobileResult")
                      : _vm.missionType === _vm.MissionType.MusicalKeys
                      ? _c("MusicalKeysMobile")
                      : _vm.mission.behavior === _vm.MissionType.JeopardAI &&
                        _vm.mode === _vm.Mode.Play
                      ? _c("JeopardAIMobile")
                      : _vm.mission.behavior === _vm.MissionType.PriceIsRight
                      ? _c("MobilePriceIsRight", {
                          key: _vm.mission.id,
                          attrs: { mission: _vm.mission, mode: _vm.mode },
                        })
                      : _vm.isVoting
                      ? _c("MobileVoting", { key: _vm.mission.id })
                      : _vm.missionType === _vm.MissionType.MatchGame &&
                        [_vm.Mode.Social, _vm.Mode.Play].includes(_vm.mode)
                      ? [
                          _vm.isLandScape
                            ? _c("ImageMatch", { key: _vm.mission.id })
                            : _c("Mission", [
                                _c("h1", [
                                  _vm._v(_vm._s(_vm.forceLandscapeText)),
                                ]),
                              ]),
                        ]
                      : _vm.missionType === _vm.MissionType.FactMatch &&
                        _vm.getCurrentMode === _vm.Mode.Huddle
                      ? [
                          _vm.isLandScape
                            ? _c("FactMatch", { key: _vm.mission.id })
                            : _c("Mission", [
                                _c("h1", [
                                  _vm._v(_vm._s(_vm.forceLandscapeText)),
                                ]),
                              ]),
                        ]
                      : _vm.isQuestionCards
                      ? _c("QuestionCards", {
                          key: _vm.mission.id,
                          attrs: { mobile: "" },
                        })
                      : _vm.isAward
                      ? _c("AwardMobile", { key: _vm.mission.id })
                      : _c(
                          "Mission",
                          { attrs: { mode: _vm.mode, mission: _vm.mission } },
                          [
                            _vm.missionType === _vm.MissionType.MultipleChoice
                              ? _c("MultipleChoice", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                    mobile: "",
                                  },
                                })
                              : _vm.isDrawingMission
                              ? _c("MobileDrawing", {
                                  attrs: {
                                    isLandScape: _vm.isLandScape,
                                    mission: _vm.mission,
                                    mode: _vm.mode,
                                  },
                                })
                              : _vm.missionType ===
                                _vm.MissionType.SpiralDrawing
                              ? _c("SpiralDrawingMobile", {
                                  attrs: { isLandScape: _vm.isLandScape },
                                })
                              : _vm.missionType === _vm.MissionType.Photo
                              ? _c("Photo", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                  },
                                })
                              : _vm.missionType === _vm.MissionType.PhotoBooth
                              ? _c("PhotoBooth", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                  },
                                })
                              : _vm.missionType === _vm.MissionType.TwoTruths
                              ? _c("TwoTruths", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                    mobile: "",
                                  },
                                })
                              : _vm.isAwardVote
                              ? _c("AwardVote", {
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                  },
                                })
                              : _vm.missionType ===
                                _vm.MissionType.TwoTruthsReveal
                              ? _c("TwoTruthsAsset", {
                                  key:
                                    _vm.mission.id +
                                    "-" +
                                    _vm.gameStatus.twoTruthsID,
                                  attrs: { mode: _vm.mode },
                                })
                              : _vm.missionType === _vm.MissionType.Poll
                              ? _c("Poll", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                    mobile: "",
                                  },
                                })
                              : _vm.missionType === _vm.MissionType.Giphy
                              ? _c("Giphy", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                    mobile: "",
                                  },
                                })
                              : _vm.missionType === _vm.MissionType.Text ||
                                _vm.missionType === _vm.MissionType.FreeForm ||
                                _vm.missionType === _vm.MissionType.TeamName
                              ? _c("TextInput", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                    maxTextLength:
                                      _vm.missionType ===
                                      _vm.MissionType.TeamName
                                        ? 30
                                        : null,
                                    mobile: "",
                                  },
                                })
                              : _vm.videoMission
                              ? _c("TakeVideo", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                  },
                                })
                              : _vm.missionType === _vm.MissionType.Lipdub
                              ? _c("Lipdub", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                    mobile: "",
                                  },
                                })
                              : _vm.missionType === _vm.MissionType.TakePhoto
                              ? _c("TakePhoto", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                  },
                                })
                              : _vm.missionType === _vm.MissionType.Categories
                              ? _c("Categories", {
                                  key: _vm.mission.id,
                                  staticClass: "mobile",
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                  },
                                })
                              : _vm.missionType ===
                                _vm.MissionType.UnconditionalPositiveRegard
                              ? _c("UnconditionalPositiveRegardMobile", {
                                  key: _vm.mission.id,
                                })
                              : _vm.isWordCloud
                              ? _c("WordCloudMobile")
                              : _vm.isBuzzable
                              ? _c("MobileBuzzIn", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                    isLandScape: _vm.isLandScape,
                                  },
                                })
                              : _vm.missionType ===
                                _vm.MissionType.DiscussionGroup
                              ? _c("DiscussionGroupMobile", {
                                  key: _vm.mission.id,
                                  attrs: { isLandScape: _vm.isLandScape },
                                })
                              : _vm.missionType === _vm.MissionType.RatingPoll
                              ? _c("RatingPoll", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                    isLandScape: _vm.isLandScape,
                                  },
                                })
                              : _vm.isTextOnly
                              ? _c("TextOnly", {
                                  key: _vm.mission.id,
                                  attrs: {
                                    mode: _vm.mode,
                                    mission: _vm.mission,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                    _vm.isMissionMeta &&
                    _vm.mission.points > 0 &&
                    _vm.mission.behavior !== _vm.MissionType.JeopardAI
                      ? _c(
                          "div",
                          { staticClass: "play-mode__wrap" },
                          [
                            _c("RtbPill", [
                              _vm._v(
                                " " + _vm._s(_vm.mission.points) + " PTS "
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.skipButton
                      ? _c(
                          "div",
                          {
                            staticClass: "play-mode__wrap skip-video",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSkipVideo()
                              },
                            },
                          },
                          [_c("RtbPillPurple", [_vm._v(" SKIP VIDEO ")])],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.hasPushToTalkBtn
        ? _c("DraggableBtn", {
            model: {
              value: _vm.speaking,
              callback: function ($$v) {
                _vm.speaking = $$v
              },
              expression: "speaking",
            },
          })
        : _vm._e(),
      _c("MobileSideBar", {
        on: {
          openChat: _vm.openChat,
          onSwapPhone: function ($event) {
            _vm.swapPhone = true
          },
          onChangeAV: function ($event) {
            _vm.aVSettings = true
          },
        },
        model: {
          value: _vm.menuActive,
          callback: function ($$v) {
            _vm.menuActive = $$v
          },
          expression: "menuActive",
        },
      }),
      _c("SwapPhone", {
        model: {
          value: _vm.swapPhone,
          callback: function ($$v) {
            _vm.swapPhone = $$v
          },
          expression: "swapPhone",
        },
      }),
      _c("MobileAVSettings", {
        model: {
          value: _vm.aVSettings,
          callback: function ($$v) {
            _vm.aVSettings = $$v
          },
          expression: "aVSettings",
        },
      }),
      _vm.chatVisible
        ? _c("MobileChat", {
            on: {
              closeChat: function ($event) {
                _vm.chatActive = false
              },
            },
          })
        : _vm._e(),
      _c(
        "Popup",
        {
          style: _vm.assetPopupStyle,
          attrs: {
            isOpen: _vm.zoomMissionAssetPhoto.visible,
            size: _vm.isViewerPresenter ? "xxl" : "",
          },
          on: {
            onClose: function ($event) {
              return _vm.closePopup()
            },
            onClickOutside: function ($event) {
              return _vm.closePopup()
            },
          },
        },
        [
          _c("v-layout", { attrs: { column: "", "justify-center": "" } }, [
            _c("div", {
              staticClass: "text-center mb-2",
              staticStyle: { "margin-top": "-16px" },
              domProps: { innerHTML: _vm._s(_vm.popupTitle) },
            }),
            _c("img", {
              staticStyle: {
                "max-width": "auto",
                "max-height": "80vh",
                transform: "translateZ(0)",
                "object-fit": "contain",
              },
              attrs: {
                src: _vm.zoomMissionAssetPhoto.photo,
                alt: "missions photo",
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }