<template>
  <div
    class="draggable-btn-content"
    :class="{
      'draggable-btn-content--active': active,
      'draggable-btn-content--dragging': dragging
    }"
  >
    <div class="draggable-btn-content__handle" v-if="!active" />

    <div class="draggable-btn-content__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DraggableBtnContent",
  props: {
    observable: Object
  },
  computed: {
    active() {
      return this.observable.active
    },
    dragging() {
      return this.observable.dragging
    },
    text() {
      if (this.active) return "TALKING"
      if (this.dragging) return "DRAG TO MOVE"
      return "TALK"
    }
  }
}
</script>

<style lang="scss">
.draggable-btn-content {
  $block: &;

  position: absolute;
  width: 80%;
  height: 80%;
  left: 10%;
  top: 10%;
  background-color: #393b42;
  border-radius: 50%;
  &__text {
    padding-top: 20px;
    padding-bottom: 20px;
    position: absolute;
    display: flex;
    color: #fff;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
    text-align: center;
  }
  &__handle {
    position: absolute;
    top: 10px;
    left: 50%;
    display: block;
    transform: translateX(-50%);
    width: 22px;
    height: 8px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
  }

  &--active,
  &--dragging {
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
  }

  &--active {
    background: radial-gradient(#97fb69, $correct-color);

    box-shadow: 0 0 10px $correct-color, 0 0 20px #fff, 0 0 30px #97fb69;

    #{$block}__text {
      align-items: normal;
    }
  }

  &--dragging {
    filter: brightness(1.3);
    box-shadow: 0 0 10px #393b42, 0 0 20px #393b42, 0 0 30px #393b42;

    #{$block}__text {
      align-items: end;
    }
  }
}
</style>
