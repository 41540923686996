var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "draggable-btn-content",
      class: {
        "draggable-btn-content--active": _vm.active,
        "draggable-btn-content--dragging": _vm.dragging,
      },
    },
    [
      !_vm.active
        ? _c("div", { staticClass: "draggable-btn-content__handle" })
        : _vm._e(),
      _c("div", { staticClass: "draggable-btn-content__text" }, [
        _vm._v(" " + _vm._s(_vm.text) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }