<template>
  <div class="draggable">
    <div class="draggable-btn" v-draggable="draggableValue">
      <div class="draggable-btn__interaction-area">
        <DraggableBtnContent v-once :observable="observable" />
        <div
          :ref="handleId"
          class="draggable-btn__handle-area"
          @touchstart="onDragStart"
          @touchend="onDragEnd"
        />
        <div
          class="draggable-btn__action-area"
          @touchstart="onTouchStart"
          @touchend="onTouchEnd"
        />
      </div>
    </div>
  </div>
</template>

<script>
const DEFAULT_BOUNDING_RECT_MARGIN = {
  top: 20,
  bottom: 20,
  left: 20,
  right: 20
}
import Vue from "vue"
import _ from "lodash"
import { mapGetters } from "vuex"
import { Draggable } from "draggable-vue-directive"
import DraggableBtnContent from "./DraggableBtnContent.vue"
import ORIENTATIONS from "./orientations"

export default {
  name: "DraggableBtn",
  directives: { Draggable },
  components: {
    DraggableBtnContent
  },
  model: {
    prop: "value",
    event: "change"
  },
  data() {
    return {
      observable: Vue.observable({ active: false, dragging: false }),
      handleId: "handle-id",
      draggableValue: {
        handle: undefined,
        resetInitialPos: true,
        boundingElement: document.body,
        boundingRectMargin: DEFAULT_BOUNDING_RECT_MARGIN,
        initialPosition: {
          top: 0,
          left: 0
        }
      },
      lastPosition: {
        [ORIENTATIONS.PORTAIT]: null,
        [ORIENTATIONS.LANDSCAPE]: null
      }
    }
  },
  computed: {
    ...mapGetters("Mobile", ["orientation", "mobile"]),
    window() {
      return this.$window
    }
  },
  watch: {
    window: {
      handler(value) {
        const defaultPosition = {
          top: value.height - 80 - DEFAULT_BOUNDING_RECT_MARGIN.top,
          left: DEFAULT_BOUNDING_RECT_MARGIN.left
        }

        const savedPosition = _.get(this.lastPosition, this.orientation)

        this.draggableValue = {
          ...this.draggableValue,
          initialPosition: savedPosition || defaultPosition
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.draggableValue.handle = this.$refs[this.handleId]
    this.draggableValue.boundingElement = document.querySelector(".draggable")
  },
  methods: {
    onDragStart() {
      this.observable.dragging = true
    },
    onDragEnd() {
      this.observable.dragging = false
      this.saveHandlePosition()
    },
    onTouchStart(e) {
      e?.preventDefault()
      this.observable.active = true
      this.$emit("change", true)
    },
    onTouchEnd(e) {
      e?.preventDefault()
      this.observable.active = false
      this.$emit("change", false)
    },
    saveHandlePosition() {
      this.lastPosition[this.orientation] = this.getHandlePosition()
    },
    getHandlePosition() {
      const draggableState = JSON.parse(
        this.$refs[this.handleId].getAttribute("draggable-state")
      )
      return draggableState.currentDragPosition
    }
  }
}
</script>

<style lang="scss">
.draggable {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
.draggable-btn {
  pointer-events: all;
  z-index: 99;
  &__interaction-area {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
  }
  &__handle-area {
    position: absolute;
    width: 100%;
    height: 30%;
  }
  &__action-area {
    position: absolute;
    width: 100%;
    height: 70%;
    bottom: 0;
  }
}
</style>
