import { render, staticRenderFns } from "./MobileMain.vue?vue&type=template&id=afcc30c0&"
import script from "./MobileMain.vue?vue&type=script&lang=js&"
export * from "./MobileMain.vue?vue&type=script&lang=js&"
import style0 from "./MobileMain.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VIcon,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/Users/dkurbatov/Documents/TGG/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('afcc30c0')) {
      api.createRecord('afcc30c0', component.options)
    } else {
      api.reload('afcc30c0', component.options)
    }
    module.hot.accept("./MobileMain.vue?vue&type=template&id=afcc30c0&", function () {
      api.rerender('afcc30c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Mobile/MobileMain.vue"
export default component.exports